@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap");

html {
    font-family: "Work Sans", sans-serif !important;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    letter-spacing: 0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

/* Mobile dropdown */
.Mui-selected {
    font-weight: 600 !important;
}

/* Map */
.leaflet-container {
    height: 100vh;
    background: transparent !important;
}

.leaflet-control-container {
    position: absolute;
    bottom: 0;
    right: 4rem;
    width: 2rem;
    height: 72px;
}

.leaflet-control-attribution {
    display: none;
}

div.leaflet-touch .leaflet-bar {
    border: none;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

div.leaflet-touch .leaflet-bar a:first-child,
div.leaflet-touch .leaflet-bar a:last-child {
    border: 1px solid #272e63;
    border-radius: 0.5rem;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.leaflet-bar a.leaflet-disabled {
    background-color: transparent;
    color: #c9c9c9;
}

div.leaflet-bar a {
    border-bottom: none;
}

div.leaflet-touch .leaflet-bar a:first-child span {
    color: #353f87;
}

div.leaflet-touch .leaflet-bar a:first-child {
    padding-bottom: 2rem;
}

fieldset {
    border: 0 !important;
}

.leaflet-container.leaflet-touch-drag {
    height: 66vh;
}

/* Scrollbar */
.scrollable::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}

.scrollable::-webkit-scrollbar-track {
    border-radius: 5.875rem;
    background: #e4e4e4;
}

.scrollable::-webkit-scrollbar-thumb {
    background-color: #4451ad;
    border: 0.18rem solid #e4e4e4;
    border-radius: 5.875rem;
}

/* Info icon */
#info:hover + div {
    display: block;
}
